<template>
    <div class="column">
      <div class="text-red text-subtitle1" v-if="error">{{template_text.SELECTONE}}</div>

    <div class="row q-gutter-xs">
<!--      <div class="col-auto " v-for="(item, index) in attribut.values" v-bind:key="index">-->
<!--        <q-btn :color="item===value?'red':'primary'" :label="labels[index]" @click="onBtn(item)"/>-->

<!--      </div>-->
      <q-btn-toggle
          v-model="form"
          spread
          no-caps
          toggle-color="red"
          color="white"
          text-color="black"
          :options="btn_opt"

      />
    </div>
      <div class="col self-end q-pa-md" v-if="forcenext">
        <q-btn color="secondary"  push icon-right="navigate_next" :label="template_text.NEXT" @click="onNext"/>
      </div>
    </div>
</template>

<script>
export default {
  name: "buttons_input",
  data(){
    return {
      form:this.value||"",
      error:false,

    }

  },
  watch: {
    form: {
      handler: function(newValue) {
        this.$emit('input', newValue);
        if(!this.forcenext) this.onNext()
      },
      deep: true,
    }
  },
  computed:{
    labels:function(){return this.attribut.label.find(o => o.language === this.language)},
    btn_opt:function() {
          let data = []
          for (let i in this.attribut.values){
            data.push({value:this.attribut.values[i],label:this.labels[i]})
          }
          return data
          }
  },
  props:{
    value:String,
    attribut:Object,
    forcenext:Boolean,
    dnext:Boolean,
    template_text:Object,
    mandatory:Boolean,
    language:String,
  },
  methods:{
    async validate(){
      if(this.form!==""){
        this.error = false
        return true
      }
      this.error=true;
      return false
    },
    onBtn(value){
      this.form=value;
      this.$emit('input', value);
      if(!this.forcenext) this.onNext()
    },
    async onNext(){
      if(await this.validate()) this.$emit('next');

    }
  },
}
</script>

<style scoped>

</style>
